import React, { useRef, useEffect, useContext } from "react";
import "./work.scss";
import gsap, { Linear, Power2 } from "gsap";
import { AppContext } from "../../data/AppContext";
import { BlobOne, BlobTwo } from "../Blobs";

export const Work = () => {
  const ref = useRef();

  const { isWorkOpen } = useContext(AppContext);

  useEffect(() => {
    const tl = gsap.timeline();
    isWorkOpen
      ? tl.to(".sectionWork", 0, { display: "flex" }).to(".sectionWork", 0.2, {
          delay: 0.8,
          left: "0vw",
          display: "flex",
          ease: Linear.easeInOut,
        })
      : tl.to(".sectionWork", 0.5, {
          left: "-100vw",
          display: "none",
          ease: Power2.easeInOut,
        });
  }, [isWorkOpen]);

  useEffect(() => {
    let marquee = document.querySelectorAll(".clipped-text");
    window.addEventListener("load", function () {
      let rate = 250;
      marquee.forEach((el) => {
        let container = el.parentElement;
        let distance = window.innerWidth;
        let totalDistance1 = distance * 2;
        let time = totalDistance1 / rate;
        gsap.to(container, time, {
          repeat: -1,
          x: "-" + totalDistance1,
          ease: Linear.easeNone,
        });
      });
    });

    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     if (entry.isIntersecting) {
    //     }
    //   },
    //   {
    //     root: null,
    //     threshold: 0.5,
    //   }
    // );
    // if (ref.current) {
    //   observer.observe(ref.current);
    // }
  }, [ref]);

  gsap.to(".blobOneWrapper", 15, {
    css: { rotate: 360 },
    ease: Linear.easeNone,
    repeat: -1,
    paused: false,
  });

  gsap.to(".blobTwoWrapper", 15, {
    css: { rotate: 360 },
    ease: Linear.easeNone,
    repeat: -1,
    paused: false,
  });

  return (
    <div className="sectionWork" ref={ref}>
      <BlobOne />
      <BlobTwo />
      <div className="wrapper">
        <div className="text-header">Let's talk tech.</div>
        <div className="rowWrapper">
          <div className="text">
            I put awesome design at the core of everything I build. I heckin'
            love a challenge, so if you're looking for something more out the
            box, I'm more than happy to have a chat.
          </div>
        </div>
        <div className="rowWrapper">
          <div className="gridWrapper">
            <div className="text-container">
              <div className="item headerItem">Mobile & Web</div>
              <div className="item">React.js</div>
              <div className="item">React Native</div>
              <div className="item">Webflow</div>
            </div>
            <div className="text-container">
              <div className="item headerItem">eCommerce & Blogs</div>
              <div className="item">Shopify</div>
              <div className="item">Wordpress</div>
            </div>
            <div className="text-container">
              <div className="item headerItem">3D & Animation</div>
              <div className="item">Three.js</div>
              <div className="item">AR Studio</div>
              <div className="item">Unity</div>
              <div className="item">Green Sock</div>
            </div>
            <div className="text-container">
              <div className="item headerItem">Misc.</div>
              <div className="item">Domain Management</div>
              <div className="item">Hosting</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
