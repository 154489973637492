import React, { useRef, useEffect, useContext } from "react";
import "./whatwedo.scss";
import gsap, { Linear, Power2 } from "gsap";
import { AppContext } from "../../data/AppContext";

export const WhatWeDo = () => {
  const ref = useRef();

  const { isWhatWeDoOpen } = useContext(AppContext);

  useEffect(() => {
    const tl = gsap.timeline();
    isWhatWeDoOpen
      ? tl.to(".section", 0.2, {
          delay: 0.8,
          bottom: "0vh",
          display: "block",
          ease: Linear.easeInOut,
        })
      : tl.to(".section", 0.5, {
          bottom: "-100vh",
          display: "none",
          ease: Power2.easeInOut,
        });
  }, [isWhatWeDoOpen]);

  useEffect(() => {
    let marquee = document.querySelectorAll(".clipped-text");
    window.addEventListener("load", function () {
      let rate = 250;
      marquee.forEach((el) => {
        let container = el.parentElement;
        let distance = window.innerWidth;
        let totalDistance1 = distance * 2;
        let time = totalDistance1 / rate;
        gsap.to(container, time, {
          repeat: -1,
          x: "-" + totalDistance1,
          ease: Linear.easeNone,
        });
      });
    });

    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     if (entry.isIntersecting) {
    //     }
    //   },
    //   {
    //     root: null,
    //     threshold: 0.5,
    //   }
    // );
    // if (ref.current) {
    //   observer.observe(ref.current);
    // }
  }, [ref]);

  return (
    <>
      <div className="section" ref={ref}>
        <div className="wrapperWhatWeDo">
          <div class="hero-marquee">
            <div class="marquee">
              <span class="clipped-text robotics">
                ecommerce web mobile VR AR motion design {"‎‏‏‎  ‎"}
              </span>
              <span class="clipped-text robotics">
                ecommerce web mobile VR AR motion design{" ‏‏‎ ‎"}
              </span>
            </div>
          </div>

          <div className="text-container">
            <div className="who-we-are-container">
              <div className="who-we-are-text-header">what i do</div>
              <div className="who-we-are-text">
                you may be starting a new gig and need help getting started, or
                you're an established company and need a new, groovy site to
                appeal to your users. or you may want to sell awesome products
                online with an e-commerce solution. anything along those lines -
                i wanna work with you.
              </div>
            </div>
            <div className="who-we-are-container">
              <div className="who-we-are-text-header">who i am</div>
              <div className="who-we-are-text">
                i'm a freelance developer building engaging, creative
                experiences for humans. i love working on awesome products and
                helping people visualise their ideas. you can find me chatting
                about design and tech on{" "}
                <a href="http://www.twitter.com/erhannah">twitter</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
