import React from "react";
import "./blob.scss";

export const BlobOne = () => {
  return (
    <div className="blobOneWrapper">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FF0066"
          d="M42.4,-66.8C53.9,-58.6,61.3,-45,69.6,-30.7C77.9,-16.3,87,-1.3,88,14.8C89,30.8,81.9,47.8,70.5,61.2C59.1,74.7,43.4,84.6,27.2,86.6C11,88.6,-5.8,82.8,-22.1,77.1C-38.4,71.5,-54.2,66.1,-64.4,55.3C-74.6,44.4,-79.2,28.2,-79.5,12.6C-79.9,-3,-76.1,-18,-69.6,-31.6C-63.1,-45.1,-53.8,-57.2,-41.7,-65.1C-29.7,-72.9,-14.8,-76.6,0.3,-77.1C15.5,-77.5,30.9,-74.9,42.4,-66.8Z"
          transform="translate(100 100)"
        />
      </svg>
    </div>
  );
};

export const BlobTwo = () => {
  return (
    <div className="blobTwoWrapper">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="rebeccapurple"
          d="M47.3,-70.3C58.9,-66,64.4,-49,66.1,-33.6C67.8,-18.2,65.8,-4.5,60.5,6.3C55.2,17.1,46.7,25.1,39.3,34.6C31.9,44.1,25.7,55.1,15.8,61.7C5.9,68.2,-7.7,70.3,-17.5,64.9C-27.3,59.6,-33.4,46.9,-45.5,37.1C-57.7,27.3,-75.8,20.4,-82.7,8.6C-89.5,-3.3,-85.1,-20,-75.1,-31.2C-65.2,-42.4,-49.8,-48,-36.4,-51.5C-23,-54.9,-11.5,-56.2,3.2,-61.1C17.8,-66.1,35.6,-74.6,47.3,-70.3Z"
          transform="translate(100 100)"
        />
      </svg>
    </div>
  );
};
