import React, { useEffect, useContext, useState } from "react";
import gsap, { Power1, Bounce } from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import { AppContext } from "../../data/AppContext";
// import WhatWeDo from "./WhatWeDo";

export const Banner = () => {
  gsap.registerPlugin(CSSRulePlugin);

  const {
    isWhatWeDoOpen,
    setIsWhatWeDoOpen,
    setIsContactOpen,
    isContactOpen,
    setIsWorkOpen,
    isWorkOpen,
  } = useContext(AppContext);
  // var rule = CSSRulePlugin.getRule(".main:after"); // get the rule
  // var ltrrule = CSSRulePlugin.getRule(".ltr"); // get the rule

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline();
    // tl.to(rule, 1, {
    //   display: "",
    //   delay: 5,
    //   duration: 5,
    //   bottom: "-150px",
    //   opacity: 1,
    // });

    tl.to(".ltr", 0.5, {
      delay: 2,
      top: -25,
      stagger: 0.03,
      ease: Power1.easeOut,
    }).to(".ltr", 0.5, {
      top: 0,
      stagger: 0.04,
      ease: Bounce.easeOut,
    });
  }, []);

  useEffect(() => {
    isWhatWeDoOpen || isContactOpen || isWorkOpen
      ? gsap.to(".main", 1, {
          scale: 0.7,
          ease: Power1.easeInOut,
        })
      : gsap.to(".main", 0.5, {
          scale: 1,
          ease: Power1.easeInOut,
        });
  }, [isWhatWeDoOpen, isContactOpen, isWorkOpen]);

  return (
    <>
      <div className="nav">
        <div
          onClick={() => {
            setIsContactOpen(false);
            setIsWhatWeDoOpen(false);
            setIsWorkOpen(!isWorkOpen);
          }}
          className="work"
        >
          tech ⟶
        </div>
        <div
          onClick={() => {
            setIsContactOpen(false);
            setIsWorkOpen(false);
            setIsWhatWeDoOpen(!isWhatWeDoOpen);
          }}
          className="about"
        >
          about ⟶
        </div>
        <div
          onClick={() => {
            setIsWhatWeDoOpen(false);
            setIsWorkOpen(false);
            setIsContactOpen(!isContactOpen);
          }}
          className="contact"
        >
          contact ⟶
        </div>
      </div>
      <div className="mobile-nav">
        <button
          onClick={() => {
            setIsContactOpen(false);
            setIsWhatWeDoOpen(false);
            setIsWorkOpen(false);
            setIsMobileMenuOpen(!isMobileMenuOpen);
          }}
          className={`hamburger hamburger--collapse ${
            isMobileMenuOpen && `is-active`
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span
              className="hamburger-inner"
              style={{ background: "white" }}
            ></span>
          </span>
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <button
            onClick={() => {
              setIsContactOpen(false);
              setIsWorkOpen(false);
              setIsWhatWeDoOpen(!isWhatWeDoOpen);
              setIsMobileMenuOpen(false);
            }}
            className="about mobile-btn"
          >
            about
          </button>
          <button
            onClick={() => {
              setIsContactOpen(false);
              setIsWhatWeDoOpen(false);
              setIsWorkOpen(!isWorkOpen);
              setIsMobileMenuOpen(false);
            }}
            className="work mobile-btn"
          >
            tech
          </button>
          <button
            onClick={() => {
              setIsWhatWeDoOpen(false);
              setIsWorkOpen(false);
              setIsContactOpen(!isContactOpen);
              setIsMobileMenuOpen(false);
            }}
            className="contact mobile-btn"
          >
            contact
          </button>
        </div>
      )}
      <div className="main">
        <div className="container">
          <div className="row">
            <h1>
              <div className="line two">
                <span>hi, i'm hannah.</span>
              </div>
              <div className="line one">
                <span>
                  let's get <span className="ltr">c</span>
                  <span className="ltr">r</span>
                  <span className="ltr">e</span>
                  <span className="ltr">a</span>
                  <span className="ltr">t</span>
                  <span className="ltr">i</span>
                  <span className="ltr">v</span>
                  <span className="ltr">e</span>
                  <span className="ltr">.</span>
                </span>
              </div>
            </h1>

            {/* <h2>
            <div className="">
              <span>impactful products</span>
            </div>
            <div className="">
              <span>creative experiences</span>
            </div>
            <div className="">
              <span>immersive content</span>
            </div>
          </h2> */}

            {/* <div className="btn-row"><a href="#/"></a></div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
