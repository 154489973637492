import React, { useEffect } from "react";
import "./App.scss";
// import Header from "./components/Header";
import { Banner } from "./components/Banner";
// import { Cases } from "./components/Cases";
import { IntroOverlay } from "./components/IntroOverlay";
import gsap from "gsap";
// import { Info } from "./components/Info";
// import { Offers } from "./components/Offers";
// import { BgText } from "./components/BgText";
// import BgShape from "./components/BgShape";
// import kursor from "kursor";
import WhatWeDo from "./components/WhatWeDo";
import { AppContextProvider } from "./data/AppContext";
import Contact from "./components/Contact";
import { Work } from "./components/Work";
import "./assets/hamburgers.css";

function App() {
  // var kursorx = new kursor({
  //   type: 1,
  // });

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from(".line", 2, {
      y: 200,
      ease: "power4.out",
      delay: 0,
      stagger: { amount: 0.3 },
      display: "none",
    });
    // .to(".overlay-top", 1, { width: 0, ease: "expo.inOut", stagger: 0.4 })
    // .to(".intro-overlay", 0, { css: { display: "none" } });
    // .to("h1", 1.4, {
    //   ease: "expo.inOut",
    //   y: -250,
    //   opacity: 0,
    // });
  }, []);

  return (
    <div className="App">
      <AppContextProvider>
        <IntroOverlay />
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <BgText /> */}
        {/* <BgShape /> */}
        <Banner />
        <WhatWeDo />
        <Work />
        <Contact />
      </AppContextProvider>

      {/* <Info /> */}
      {/* <Offers /> */}
      {/* <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a> */}
    </div>
  );
}

export default App;
