import React, { useRef, useEffect, useContext } from "react";
import "./contact.scss";
import gsap, { Linear, Power2, Bounce } from "gsap";
import { AppContext } from "../../data/AppContext";

export const Contact = () => {
  const ref = useRef();

  const { isContactOpen } = useContext(AppContext);

  useEffect(() => {
    const tl = gsap.timeline();
    isContactOpen
      ? tl.to(".sectionContact", 0.2, {
          delay: 0.8,
          left: "0",
          display: "block",
          ease: Linear.easeInOut,
        })
      : tl.to(".sectionContact", 0.5, {
          left: "-100vw",
          ease: Power2.easeInOut,
        });
  }, [isContactOpen]);

  useEffect(() => {
    animateGsap();
  }, [ref]);

  gsap.to(".service-text ", 1, {
    color: "#000",
    yoyo: true,
    stagger: { each: 0.5, from: "random" },
    ease: Bounce.easeInOut,
    repeat: -1,
  });

  const animateGsap = () => {
    const tl = gsap.timeline();
    tl.to(".service-wrapper", 2, {
      y: -40,
      opacity: 1,
      visibility: "visible",
    });
  };

  const serviceList = [
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
    "SKETCHING",
    "DESIGNING",
    "PROTOTYPING",
    "COLLABORATING",
    "MAKING",
    "ANIMATING",
    "TESTING",
    "ITERATING",
    "BUILDING",
    "LAUNCHING",
  ];

  return (
    <>
      <div className="sectionContact" ref={ref}>
        <div className="wrapper">
          <div className="services">
            <div className="inner-wrapper">
              {serviceList.map((e, index) => {
                return <span className={`service-text _${index}`}>{e}</span>;
              })}
            </div>
          </div>
          <div className="contact-wrapper">
            <div className="contact-text-header">ready for a chat?</div>
            <div className="contact-text">
              if you're interested in working together, let's talk. i'm happy to
              offer a free consultation to see if we're a good match. it's
              important we're able to have fun building your ideas! you can
              contact me via{" "}
              <a href="mailto:hello@hannahblair.co.uk">
                hello@hannahblair.co.uk
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
